import "./App.css";
import { Suspense, useState } from "react";
import LocaleContext from "./LocaleContext";
import Loading from "./components/loading";
import i18n from "./i18n";
import Home from "./components/page";
import HamburgerMenu from "./components/HamburgerMenu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Poulet from "./components/pages/Poulet";
import Restaurant from "./components/pages/Restaurant";
import Menu from "./components/pages/Menu";
import Hotel from "./components/pages/Hotel";
import Contact from "./components/pages/Contact";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    const [locale, setLocale] = useState(i18n.language);
    const [bool, setBool] = useState(false);
    i18n.on("languageChanged", (lng) => {
        setLocale(i18n.language);
        setBool(!bool);
    });

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <ScrollToTop />
                    <HamburgerMenu />
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="/chicken" element={<Poulet />} />
                        <Route path="/restaurant" element={<Restaurant />} />
                        <Route path="/menu" element={<Menu />} />
                        <Route path="/hotel" element={<Hotel />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </LocaleContext.Provider>
    );
}

export default App;
